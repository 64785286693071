export default [
  {
    value: '1521', label: 'Stromaggregat Otto fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '1522', label: 'Stromaggregat Diesel fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3228', label: 'Kompostieranlage fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3228', label: 'Separationsanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3301', label: 'Leiter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3301', label: 'Feuerspritze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3301', label: 'Hubsteiger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3301', label: 'Hubarbeitsbühne (selbstfahrend)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3301', label: 'Feuerwehrfahrzeug (Spritz- und Leiterwagen)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3302', label: 'Hubarbeitsbühne (Anhänger)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Sprengwagen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Rasenmäher', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Schneeräumgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Golfplatzpflegegerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Golfplatzfahrzeug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Kanalspülgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3401', label: 'Kehrmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3501', label: 'Segway', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3502', label: 'E-Fahrräder', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3604', label: 'Verladebrücke', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3907', label: 'Wagen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3907', label: 'Loren', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '4202', label: 'Bohranlage für Erdöl und Erdgas', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '4253', label: 'Torffräse', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5285', label: 'Schweißgleichrichter fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5285', label: 'Schweißtransformator fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5285', label: 'Schweißumformer fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6301', label: 'Traktor', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6301', label: 'Schlepper', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6311', label: 'Mähdrescher', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6311', label: 'Mähmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6312', label: 'Traubenernter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6312', label: 'Zuckerrübenvollernter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6312', label: 'Kartoffelvollernter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6313', label: 'Grubber', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6313', label: 'Gülleausbringfahrzeug, selbstfahrend', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6314', label: 'Gülleausbringfahrzeug, nicht selbstfahrend', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6315', label: 'Häcksler (Mais-, Heu-, Stroh- u. Grassilage)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6316', label: 'Ballenpresse (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6316', label: 'Rundballenpresse (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6317', label: 'Verladeeinrichtung (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6317', label: 'Ladewagen (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6317', label: 'Fischermaus', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6317', label: 'Futtermittelmischwagen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6317', label: 'Silagewagen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Sonstiges Gerät (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Walze (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Pflug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Sämaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Anbaugerät (Landwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Egge', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Gurkenflieger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Heuwender', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Feldspritze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6319', label: 'Düngerstreuer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Arbeitsgerät (Forstwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Baumsäge fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Holzerntemaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Harvester', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Vollernter (Forstwirtschaft)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Holzrückezug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Holzhäcksler', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Holzschredder', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Holzhacker', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6320', label: 'Brennholzautomat', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6321', label: 'Sägewerk mobil', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6401', label: 'Langlaufspurgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6401', label: 'Motorschlitten', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6401', label: 'Pistenraupe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6401', label: 'Pistenwalze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '6402', label: 'Schneekanone', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7001', label: 'Steinbrecher transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7001', label: 'Mühle transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7001', label: 'Walzenzerkleinerer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7002', label: 'Kieswaschmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7002', label: 'Sandgewinnungsmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7002', label: 'Siebanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7011', label: 'Trocknungsanlage transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7011', label: 'Mischanlage transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7011', label: 'Mischturm transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7011', label: 'Betontankstelle (mobil)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7013', label: 'Transportbirne', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7013', label: 'Transportmischer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7013', label: 'Betonmischer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7015', label: 'Wiegeeinrichtung', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7015', label: 'Klassierungseinrichtung', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7015', label: 'Abmesseinrichtung', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7015', label: 'Abzugsgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7015', label: 'Aufgeber', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7016', label: 'Klärschlammtrocknungsanlage, mobil', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '7018', label: 'Silo transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7018', label: 'Behälter transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7031', label: 'Turmdrehkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7031', label: 'Schnellbaukran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7033', label: 'Laufkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7033', label: 'Portalkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7033', label: 'Bockkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7033', label: 'Derrickkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7033', label: 'Kabelkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7034', label: 'Mobilkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7034', label: 'Autokran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7034', label: 'Ladekran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7035', label: 'Raupenkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7035', label: 'Eisenbahnkran', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7041', label: 'Winde transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7041', label: 'Zug (Zieheinrichtung)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7041', label: 'Kleinstschrapperanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7041', label: 'Bauaufzug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7041', label: 'Becherwerk transportabel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7044', label: 'Bandfördereinrichtung', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7044', label: 'Förderschnecke', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7044', label: 'Teleskopband', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7045', label: 'Zementpumpe einschl. Rohrleitungen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7045', label: 'Betonpumpe einschl. Rohrleitungen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7045', label: 'Putzmaschine (Mörtel)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7046', label: 'Autobetonpumpe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7051', label: 'Presspumpe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7051', label: 'Hebebock', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7061', label: 'Gabelstapler (Verbrennungsmotor)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7061', label: 'Hubstapler (Verbrennungsmotor)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7062', label: 'Elektrokarren', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7062', label: 'Schlepper (Elektro)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7062', label: 'Gabelstapler (Elektro)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7062', label: 'Hubstapler (Elektro)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7064', label: 'Autohebebühne (selbstfahrend einschl. Fzg.)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7071', label: 'Triebzug (Elektro)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7071', label: 'Lokomotive (Elektro)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7071', label: 'Elektrolokomotive', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7072', label: 'Diesellokomotive', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7072', label: 'Lokomotive (Diesel)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7072', label: 'Dieseltriebzug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7073', label: 'Waggon (Personen/Güter)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7074', label: 'Stadtbahn', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7074', label: 'Straßenbahn', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7074', label: 'U-Bahn', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7074', label: 'S-Bahn', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7075', label: 'Dampflokomotive', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'LKW-Muldenkipper (Baugewerbe)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'Muldenkipper (Baugewerbe)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'Absetzkipper (Baugewerbe)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'Dumper (Baugewerbe)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'Presscontainer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7098', label: 'Müllpresse', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Scrapper', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Motorschürfwagen', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Motorstraßenhobel', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Planierraupe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Radbagger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Radlader', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Raupenbagger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Raupenlader', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Schaufelradbagger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Schrapperanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Aufreißer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Bagger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Eimerkettenbagger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Grabenziehmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Grader', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Teleskopstapler', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Teleskoplader', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7106', label: 'Asphaltfräse', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Ladegerät (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Radlader (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Raupe (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Schürfgerät (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Kettenbagger (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7107', label: 'Bagger (Abbruch/Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Minibagger bis EUR 25.000', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Miniladegerät bis EUR 25.000', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Miniraupenbagger bis EUR 25.000 ', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Miniraupe bis EUR 25.000', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Miniradlader bis EUR 25.000 ', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7108', label: 'Minischürfgerät bis EUR 25.000', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7301', label: 'Pfahlzieher', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7301', label: 'Ramme', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7311', label: 'Stampfgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7311', label: 'Rüttelgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7311', label: 'Vibrationsplatte', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7321', label: 'Walze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7321', label: 'Dieselwalze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7321', label: 'Gummiradwalze', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7321', label: 'Walzenzug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7401', label: 'Bohranlage (Brunnenbau, Erdbohrung)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7511', label: 'Schwarzasphaltdeckenfertiger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7511', label: 'Betondeckenfertiger (Straßenbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7511', label: 'Bodenvermörtelungsgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7511', label: 'Gussasphaltdeckenfertiger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7511', label: 'Bodenschleifmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7551', label: 'Gleisbettreinigungsmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7551', label: 'Gleisrichtmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7551', label: 'Gleisstopfmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7551', label: 'Schotterplaniermaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Kompressor fahrbar', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Sandstrahlgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Druckluftbehälter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Gesteinsbohrmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Drucklufthammer', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Druckluftschleuse', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7601', label: 'Hochdruckreiniger', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7651', label: 'Bohrwagen (Tunnelbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7652', label: 'Wurfschaufellader (Tunnelbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7653', label: 'Stollenbohrmaschine (Tunnelbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7654', label: 'Schildvortriebsmaschine (Tunnelbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7655', label: 'Tunnelaufschlitzmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7656', label: 'Erdrakete', selbstbeteiligung: '2500', percent: null, sbMin: null
  },
  {
    value: '7657', label: 'Kanaluntersuchungsgerät', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '7657', label: 'Kanalsanierungsgerät', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '7659', label: 'Sonstiges Gerät (Tunnelbau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7711', label: 'Lehrgerüst für Brückenbau', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7711', label: 'Schalungsgerüst', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7711', label: 'Gerüst (Maschinell)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7711', label: 'Hochbaugerüst', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Traglufthalle', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Werkstatt/Bau', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Zelt', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Labor', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Magazin', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Schutzdach', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Baubaracke', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Baubude', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Baubüro', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Container (nicht Kühlung)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7851', label: 'Abrollbehälter', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7853', label: 'Signalanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7853', label: 'Sicherungsanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7853', label: 'Vermessungsgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7853', label: 'Laborgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7853', label: 'Funkgerät', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '7859', label: 'Sonst. Einrichtung und Maschine (Bau)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '8098', label: 'LKW-Muldenkipper (Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '8098', label: 'Muldenkipper (Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '8098', label: 'Absetzkipper (Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '8098', label: 'Dumper (Steinbruch/Schrott)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
];
