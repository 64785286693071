export default [
  { value: 250, label: '250 EUR' },
  { value: 500, label: '500 EUR' },
  { value: 750, label: '750 EUR' },
  { value: 1000, label: '1.000 EUR' },
  { value: 1500, label: '1.500 EUR' },
  { value: 2000, label: '2.000 EUR' },
  { value: 2500, label: '2.500 EUR' },
  { value: 3000, label: '3.000 EUR' },
  { value: 4000, label: '4.000 EUR' },
  { value: 5000, label: '5.000 EUR' },
  { value: 10000, label: '10.000 EUR' },
  { value: 20000, label: '20.000 EUR' },
  { value: 25000, label: '25.000 EUR' },
  { value: 50000, label: '50.000 EUR' },
  { value: 75000, label: '75.000 EUR' },
  { value: 100000, label: '100.000 EUR' },
  { value: 150000, label: '150.000 EUR' },
  { value: 200000, label: '200.000 EUR' },
  { value: 250000, label: '250.000 EUR' },
  { value: 500000, label: '500.000 EUR' },
  { value: 750000, label: '750.000 EUR' },
  { value: 1000000, label: '1.000.000 EUR' },
];
