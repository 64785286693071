export default [
  {
    value: '1001', label: 'Heizungs-/Warmwasserkessel m.Tank/Silo', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1001', label: 'Öltank für Heizung und Warmwasser', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1001', label: 'Hackschnitzelheizung inkl. Pufferspeicherung u.Pumpentechnik', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1001', label: 'Solarthermie inkl. Pufferspeicher und Pumpentechnik - ohne Wärmepumpe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1001', label: 'Heizungskessel m.Tank/Silo', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1003', label: 'Ölerhitzungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1012', label: 'Schnelldampferzeuger', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1012', label: 'Dampfautomat', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1012', label: 'Dampfgenerator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1012', label: 'Durchlauferhitzer', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1302', label: 'Lokomobil', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1302', label: 'Dampfmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1302', label: 'Schiffsdampfmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1302', label: 'Expansionsmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1302', label: 'ORC-Turbine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1501', label: 'Stromaggregat mit Ottomotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1501', label: 'Benzinmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1501', label: 'Vergasermotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1501', label: 'Notstromaggregat mit Ottoantrieb', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1501', label: 'Ottomotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1502', label: 'Stromaggregat mit Dieselmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1502', label: 'Mehrstoffmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1502', label: 'Notstromaggregat mit Dieselantrieb', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1502', label: 'Ölmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1502', label: 'Dieselmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1533', label: 'Blockheizkraftwerk mit Kolbenmaschine (kein Bio-, Holzgas/Pflanzenöl/Wasserstoff)', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '1533', label: 'Blockheizkraftwerk mit Kolbenmaschine (Pflanzenöl)', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '1535', label: 'Biogasanlage mit Zündstrahlverfahren', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1536', label: 'Biogasanlage mit Gasottoverfahren', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1537', label: 'Fermenterbiologie Biogas Zündstrahl', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1538', label: 'Fermenterbiologie Biogas Gasotto mit/ohne Motor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1539', label: 'Biogasanlage o. Verbrennungsmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1541', label: 'Flugmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1541', label: 'Flugtriebwerk', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1541', label: 'Hubschrauberantrieb', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1551', label: 'Schiffsantriebsmotor einschl. Getriebe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1601', label: 'Transmissionen in Luft- und Raumfahrt', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1601', label: 'Vorgelege in Luft- und Raumfahrt', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1601', label: 'Getriebe in Luft- und Raumfahrt', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1801', label: 'Elektromotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1801', label: 'Elektrozug', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1803', label: 'Umformer', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1803', label: 'Kontaktumformer', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1803', label: 'Kontaktgleichrichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1803', label: 'Motorgenerator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '1803', label: 'Phasenschieber', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2001', label: 'Messanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2001', label: 'Regelanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2001', label: 'Mess- u. Regelanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2001', label: 'Schaltanlage (Hoch-u.Niederspannung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2001', label: 'Schaltwarte', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2103', label: 'Kompensationsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2103', label: 'Kondensator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2103', label: 'Akkumulatorenbatterie', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2104', label: 'Freileitung einschl. Masten', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2105', label: 'Erdkabel', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2107', label: 'Schwimmkabel', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2107', label: 'Seekabel', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2107', label: 'Flusskabel', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2201', label: 'Transformator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Spannungswandler', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Stromrichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Stromwandler', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Umrichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Ladegerät elektrische Versorgung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Messwandler', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Drosselspule', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Erdschlussspule', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2203', label: 'Gleichrichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2301', label: 'Pumpe (Wasser/Fernwärme)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2302', label: 'Unterwasserpumpe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2302', label: 'Eintauchpumpe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2303', label: 'Rohrleitung (Wasser/Fernwärme)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2303', label: 'Armatur (Wasser/Fernwärme)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2305', label: 'sonstige Anlage (Wasser/Abw./Pumpw.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2305', label: 'Abscheideanlage (Wasser)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2305', label: 'Desinfektionsanlage für Trinkwasser', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2390', label: 'Kläranlage ohne Gasmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2391', label: 'Kläranlage mit Gasmotor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2401', label: 'Abfackelungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2401', label: 'Gasreinigungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2401', label: 'Gasspaltanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2451', label: 'Elektrolyseanlage/PEM', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '2452', label: 'Wasserstofftank/-behälter', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '2453', label: 'Verdichter', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '2454', label: 'Rohrleitungen/Armaturen (Wasserstoff)', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '2455', label: 'Wasserstofftankstelle inkl. Tanks/Behälter', selbstbeteiligung: '1000', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Kompressor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Kolbenkompressor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Verdichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Drucklufterzeuger', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Rotationskompressor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Schraubenkompressor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Turbokompressor', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2502', label: 'Rotationsgebläse mit Antrieb', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2511', label: 'Behälter für Gas', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2512', label: 'Rohrleitung für Gas', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2512', label: 'Armatur für Gas', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2519', label: 'sonstige Anlage für Gas', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2601', label: 'Speiseeisbereiter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2601', label: 'Eismaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2601', label: 'Tiefkühltruhe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2601', label: 'Absorptions-Kälteanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2601', label: 'Kältemaschine (Kälteerzeug./-Versorg.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2604', label: 'Verdampfer (Kälteerzeug./-Versorg.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2604', label: 'Kondensator (Kälteerzeug./-Versorg.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2604', label: 'Rohrleitung (Kälteerzeug./-Versorg.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2604', label: 'Armatur (Kälteerzeug./-Versorg.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2721', label: 'sonstige Einrichtung für Kraftanlagen', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2721', label: 'Tank für Kraftanlagen', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2721', label: 'Kühlturm', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '2721', label: 'Behälter in Kraftanlagen', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3019', label: 'Messgerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3019', label: 'Prüfgerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3204', label: 'Pumpe (Heizung/Hauswasser/Abwasser)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3204', label: 'Rohrleitung (Heizung./Hausw./Abwasser)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3204', label: 'Armatur (Heizung/Hauswasser/Abwasser)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3205', label: 'Klimaanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3205', label: 'lufttechnische Anlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3206', label: 'Wärmepumpe', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3207', label: 'Wärmepumpe in Wohngebäude', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Lastenaufzug (Haustechnik)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Paternoster', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Personenaufzug', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Rolltreppe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Fahrstuhl', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3211', label: 'Fahrtreppe', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3213', label: 'Rolltor', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3213', label: 'Schrankenanlage', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '3222', label: 'Müllzerkleinerungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3222', label: 'Presse (Müll)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3227', label: 'Kompostieranlage stationär', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3229', label: 'sonstige Arbeitsmasch. (Müll/Kompost)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3500', label: 'Fundament', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3601', label: 'Kran ortsfest', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3601', label: 'Lastenaufzug (Transporteinrichtung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3601', label: 'Hallenlaufkran', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Slipanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Spillanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Winde (Transporteinrichtung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Saugfördereinrichtung (Transporteinrichtung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Bandfördereinrichtung (Transporteinrichtung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Becherwerk (Transporteinrichtung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3704', label: 'Kettenfördereinrichtung (Transporteinrichtung).', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'sonstige maschinelle Lagereinrichtung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Kippeinrichtung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Rollenförderer', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Schiebebühne', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Gleiswaage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Fahrzeugwaage (LKW)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Hebebühne stationär', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3731', label: 'Hochregallager', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3741', label: 'Drahtseilbahn in der Industrie', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3751', label: 'Beschickungsautomat', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '3751', label: 'Industrieroboter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4001', label: 'Fördermaschine (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4002', label: 'Maschine unter Tage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4101', label: 'Abraumfördergerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4101', label: 'Bandabsetzer (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4102', label: 'Zerkleinerungsanlage (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4102', label: 'Prallspalter (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4102', label: 'Schlägermühle (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4102', label: 'Durchlaufbrecher (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4102', label: 'Hammerbrecher f. Bergbau', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4121', label: 'Brikettpresse (keine Kohle)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4129', label: 'sonstige Arbeitsmaschine (Bergbau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4259', label: 'Torfpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4259', label: 'Torfverarbeitungsmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4259', label: 'Zerkleinerungsmaschine Torf', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4514', label: 'Walzwerk (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4514', label: 'Zerkleinerungsmaschine (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4514', label: 'Mischer (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4514', label: 'Rührwerk (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4519', label: 'sonstige Arbeitsmaschine (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4519', label: 'Tablettiermaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4519', label: '3D-Drucker (Chemie)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4521', label: 'Zerkleinerungsm. (Kunststoff/Gummi/Lino.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4521', label: 'Granulieranlage (Kunststoff/Gummi/Lino.)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4522', label: 'Walzwerk (Kunststoff/Gummi/Linoleum)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4522', label: 'Kalander (Kunststoff/Gummi/Linoleum)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4523', label: 'Kunststoff-Schweißmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4523', label: 'Foliermaschine (Kunststoff/Gummi/Linoleum)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Spritzgießmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Kunststoffspritzgießmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Vulkanisierpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Plattenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Extruder', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Kolbenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Kolbenschneckenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4531', label: 'Schneckenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4532', label: '3D-Drucker f. Kunststoff', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4536', label: 'Kunststoff-Fensterrahmenfertigungsanl.', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4539', label: 'sonstige Arbeitsmaschine für Kunststoff', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4539', label: 'Dentalfräsmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4539', label: 'Verpackungsmaschine (Kunststoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4559', label: 'Presse (Leder)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4559', label: 'Sonstige Arbeitsmaschine (Leder)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Stanze (Textil)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Textildruckmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Krempelsatz', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Öffnerzug', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Reißwolf (Textil)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Ballenpresse für Textil', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4601', label: 'Karden (Textil)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4609', label: 'Stickmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4609', label: 'Sonstige Arbeitsmaschine (Textil)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Trockner f. Wäsche', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Plättmaschine f. Wäsche', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Reinigungsanlage f. Wäsche', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Bügelmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Bügelpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4701', label: 'Färbanlage f. Wäsche', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '4709', label: 'Sonst. Arbeitsmaschine Reinigung/Wäscherei', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5001', label: 'Shredder (Schrott)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5001', label: 'Brecheranlage (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5001', label: 'Durchlaufbrecher (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5001', label: 'Hammerbrecher (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5001', label: 'Hammermühle (Schrott)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5002', label: 'Zerkleinerungsanl. (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5002', label: 'Kohlenstaubmühle (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5002', label: 'Prallspalter (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5002', label: 'Schlägermühle (Metallaufbereitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5005', label: 'Konverter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5005', label: 'Hochofenanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5009', label: 'Sonst. Arbeitsmasch. (Metallaufbereitung).', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5062', label: 'Schrottpakettierpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5062', label: 'Schrottschere', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5151', label: 'Trocknungsanlage (Gießerei)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5151', label: 'Mischanlage (Gießerei)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5151', label: 'Mühle (Gießerei)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5151', label: 'Sandaufbereitungsanlage (Gießerei)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5152', label: 'Formmaschine (Gießerei)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5161', label: 'Schmelzofen elektrisch beheizt', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5163', label: 'Schmelzofen gas-, flamm- oder ind. beh.', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5171', label: 'Spritzgussanlage (Metall)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5171', label: 'Metallgussanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5171', label: 'Gießmaschine (Metall)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5179', label: 'Sonst. Arbeitsmasch. f. Metallschmelze', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Spindelpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Stranggusspresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Kniehebelpresse ', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Friktionspresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Kurbelexcenterpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Presse für Metall', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Schraubenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Hydraulikpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5194', label: 'Kabelummantelungspresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5201', label: 'Profilwalzwerk', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5201', label: 'Rohrwalzwerk', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5201', label: 'Blechwalzwerk', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5201', label: 'Drahtwalzwerk', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Stanze (Metallverformung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Schere (Metallverformung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Abkantpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Biegemaschine (Metallverformung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Biegezentrum (Metallverformung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Rundknetmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5221', label: 'Profilbiegemaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5229', label: 'Sonstige Anlage (Metallverformung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5231', label: 'Ofen (Metallwärmebehandlung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5239', label: 'Sonst. Anlage (Metallwärmebehandlung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5261', label: 'Walzmaschine (Blechbearbeitung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5261', label: 'Nietanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5261', label: 'Nietmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5261', label: 'Bördelmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5262', label: 'Drahtziehmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5264', label: 'Blechbedruckmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5269', label: 'Sonstige Blechverarbeitungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5269', label: 'Sonstige Drahtverarbeitungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5281', label: 'Gasschmelzschweißanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Metallaufspritzmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Lichtbogenschneidemaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Lichtbogenschweißmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Schweißgleichrichter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Schweißtransformator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5282', label: 'Schweißumformer', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5284', label: 'Stumpfschweißmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5284', label: 'Punktschweißmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5284', label: 'Schweißautomat', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5284', label: 'Schweißroboter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5284', label: 'Schweißstraße', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5286', label: 'Laserschneideanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5286', label: 'Plasmaschneideanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5287', label: '3D-Drucker (Metall)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'Schleifmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'CNC-Schleifmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'Bearbeitungszentum (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'CNC-Bearbeitungszentrum (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'Bohrmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'CNC-Bohrmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'Fräsmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'CNC-Fräsmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'Hobelmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5301', label: 'CNC-Hobelmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5302', label: 'Drehbank (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5302', label: 'CNC-Drehbank (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5303', label: 'Stoßmaschine (Metall)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5304', label: 'Funkenerosionsmaschine', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5309', label: 'Sonstige Arbeitsmaschine (Metall)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5309', label: 'Entgrater (Metall)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5311', label: 'Mechanische Einrichtung (Galvanik)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5312', label: 'Elektr. Beheizungseinrichtung (Galvanik)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5359', label: 'Sonst. Anlage Metallbehandlung/Galvanik', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5359', label: 'Lackieranlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5359', label: 'Pulverbeschichtung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5501', label: 'Baumsäge stationär', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5501', label: 'Gatter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5502', label: 'Spann- und Hilfswagen', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5520', label: 'Kurztaktpresse', selbstbeteiligung: '5000', percent: null, sbMin: null
  },
  {
    value: '5520', label: 'Profilrahmenpresse', selbstbeteiligung: '5000', percent: null, sbMin: null
  },
  {
    value: '5520', label: 'Beschichtungspresse', selbstbeteiligung: '5000', percent: null, sbMin: null
  },
  {
    value: '5520', label: 'Einetagenspanplattenpresse', selbstbeteiligung: '5000', percent: null, sbMin: null
  },
  {
    value: '5521', label: 'Presse (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5523', label: 'Mehretagenspanplattenpresse', selbstbeteiligung: '25000', percent: null, sbMin: null
  },
  {
    value: '5523', label: 'Conti-Roll-Presse', selbstbeteiligung: '25000', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Stanzmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Zerkleinerungsmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Schere (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Furniermessermaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Hackrotor (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5552', label: 'Holzshredder (kein Müll)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5558', label: 'Bearbeitungszentrum (Holz)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5558', label: 'CNC-Bearbeitungszentrum (Holz)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5558', label: 'Abbundmaschine (Holz)', selbstbeteiligung: '500', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Sonstige Arbeitsmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Trockner (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Säge (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Schälmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Schleifmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Fräsmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5559', label: 'Hobelmaschine (Holz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5601', label: 'Papiermaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5601', label: 'Kartonmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5611', label: 'Zerkleinerungsmaschine (Papier/Karton)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5619', label: 'Sonstige Arbeitsmaschine Papiererzeug.', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5621', label: 'Wellpappenmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5621', label: 'Kaschiermaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5622', label: 'Stanzmaschine Papierverarbeitung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5622', label: 'Schere Papierverarbeitung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5622', label: 'Schneidemaschine Papierverarbeitung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5629', label: 'Sonst. Arbeitsmaschine Papierverarb.', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5629', label: 'Papierballenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5629', label: 'Verpackungsmaschine (Papier, Pappe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5651', label: 'Rollenrotationsmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5652', label: 'Siebdruckmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5652', label: 'Tiegeldruckmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5652', label: 'Zylinderautomat', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5652', label: 'Schnellpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5652', label: 'Druckpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5653', label: 'Offsetmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5653', label: 'Offsetrotationsmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5653', label: 'Bogenoffsetmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5653', label: 'Bogenrotationsmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5654', label: 'Stanze (graphisches Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5654', label: 'Kniehebelpresse (graph. Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5654', label: 'Prägepresse (graphisches Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5655', label: 'Rollenoffsetmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5656', label: 'Digitaldruckmaschine', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Vervielfältigungsgerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Klischograph', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Reproduktionsgerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Scanner', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Chromograph', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5657', label: 'Graviereinrichtung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5658', label: 'Schere (graphisches Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5658', label: 'Schneidemaschine (graph. Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5659', label: 'Sonst. Arbeitsmaschine (graph. Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5659', label: 'Zusammentragmaschine (graph. Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5659', label: 'Locher', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5659', label: 'Falzmaschine (graphisches Gewerbe)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5659', label: 'Hefter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5661', label: 'Chromacomsystem', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5661', label: 'Graphisches Gestaltungssystem', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5662', label: 'Lichtsatzanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5662', label: 'Fotoanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5663', label: 'Filmentwicklungsgerät', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5802', label: 'Sonstige Arbeitsmaschine für Zucker', selbstbeteiligung: null, percent: 20, sbMin: 1000
  },
  {
    value: '5802', label: 'Zentrifuge für Zucker', selbstbeteiligung: null, percent: 20, sbMin: 1000
  },
  {
    value: '5852', label: 'Sterilisator (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Kochkessel (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Rektifizieranlage (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Apparat unter Dampfdruck', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Autoklav (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Dampftunnel (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Destillationsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Extraktionsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Homogenisieranlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5852', label: 'Abfüllanlage (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Walzwerk (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Wolf (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Zerkleinerungsanlage (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Kneter (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Kollergang (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Mühle (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Presse (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Rührwerk (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Brecher (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Cutter (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Fruchtpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Hopfenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Kelterpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5901', label: 'Dekanter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5903', label: 'Ofen (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5903', label: 'Röster (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5903', label: 'Grünfuttertrocknungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '5903', label: 'Getreidetrocknungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6209', label: 'Sonstige Arbeitsmaschine (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6209', label: 'Schneidemaschine (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6209', label: 'Behälter (Nahrung)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6209', label: 'Flaschenreinigungsanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6209', label: 'Flaschenabfüllanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6329', label: 'Transportanlage stationär (Landwirtschaft)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6329', label: 'Melkanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6329', label: 'Sonst. Maschine stationär (Landwirtschaft)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6329', label: 'Futterdosieranlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6329', label: 'Melkroboter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Spülmaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Staubsauger (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Ventilator (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Wäschezentrifuge (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Waschmaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Trockenapparat (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Kücheneinrichtung techn. (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Mixmaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Schneidmaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Bohnermaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Exhaustor (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Kaffeemaschine (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6509', label: 'Heißmangel (Haus/Hotel/Küche/KH)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Umwälzanlage für Schwimmbad', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Kunsteisbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Schwimmbad', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Eisaufbereitung für Kunsteisbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Kältemaschine für Kunsteisbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6611', label: 'Floatingbecken', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6659', label: 'Sporteinrichtung techn.', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6659', label: 'Schießstandeinrichtung', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6659', label: 'Flutlichtanlage mit Masten', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6659', label: 'Kegelbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6701', label: 'Tankstelleneinrichtung (ohne Waschanlage)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6702', label: 'Waschanlage (Portal- und SB-Waschanlage) KFZ', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6711', label: 'Tank für Tankstelle', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6711', label: 'Behälter für Tankstelle', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6729', label: 'Sonstige Arbeitsmaschine (Tankst./Kfz)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6819', label: 'Sonstige Anlage (Film/Funk/Kino/Theater)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6911', label: 'Drahtseilbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6911', label: 'Gondelbahn', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6921', label: 'Sessellift', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6921', label: 'Skilift', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6921', label: 'Schlepplift', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6959', label: 'Silo (Lager/Umschlaganlage)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6959', label: 'Sonstige Anlage (Lager/Umschlaganlage)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '6959', label: 'Behälter (Lager/Umschlaganlage)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Schwimmkran', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Spüler (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Löffelbagger (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Sauger (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Saugförderanlage (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Schwimmelevator', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Eimerkettenschwimmbagger', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Greifer für Kiesgewinnung (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Förderbandanlage (schwimmend)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8701', label: 'Saugbagger', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8901', label: 'Steinbrecher stationär', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8901', label: 'Kreiselbrecher (Steine)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8901', label: 'Mühle (Steine)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8901', label: 'Backenbrecher', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8903', label: 'Steingatter', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8903', label: 'Steinsäge', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8903', label: 'Trennmaschine (Stein)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8904', label: 'Wasserstrahlschneideanlage', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Ofen (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Brennofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Trockenofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Röstofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Härteanlage (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Drehrohrofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Schachtofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Tunnelofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Glasschmelzofen (Baustoff/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Rekuperativofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8921', label: 'Kühlofen (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Steinpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Ziegelpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Hartsteinpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Bauplattenpresse', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Walzwerk (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8931', label: 'Kollergang (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8951', label: 'Stampfgerät (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8951', label: 'Rüttelgerät (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8951', label: 'Formmaschine (Baustoff/Steinbruch/Glas)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8952', label: '3D-Drucker (Bau)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8969', label: 'Sonstige Arbeitsmaschine (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8969', label: 'Betonmischanlage (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
  {
    value: '8969', label: 'Betontankstelle (Baustoff)', selbstbeteiligung: '250', percent: null, sbMin: null
  },
];
